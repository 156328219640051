import React from "react"
import { useThemeUI } from "theme-ui"
import Text from "./text"
import Box from "./box"

const SkipContent = (props) => {
  const context = useThemeUI()
  const { theme } = context

  return (
    <Box
      as="a"
      bg="black"
      href="#inhalt"
      p={3}
      position="absolute"
      left="50%"
      top={6}
      zIndex={50}
      aria-label="Zum Inhalt springen"
      sx={{
        opacity: 0,
        transform: `translateY(calc(-100% - ${theme.space[6]})) translateX(-50%)`,
        transition: `all ${theme.transitions["main"]}`,
        "&:focus": {
          opacity: 1,
          transform: "translateY(0) translateX(-50%)",
        },
      }}
    >
      <Text as="span" color="white" sans bold>
        Zum Inhalt springen
      </Text>
    </Box>
  )
}

export default SkipContent
